@import url('https://fonts.googleapis.com/css2?family=Figtree:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: "Figtree", sans-serif;
    user-select: none;
    -moz-user-select: none;
    -webkit-text-select: none;
    -webkit-user-select: none;
    outline: none;
}

body {
    margin: 0;
    padding: 0;
    background-color: var(--lightgrey);
}

:root {
    --green: #07EA77;
    --darkgreen: #29645D;
    --darkestgreen: #073028;
    --lightgrey: #F2F1F6;
    --hovergrey: #E6E4ED;
    --textgrey: #A9A8AA;
    --midgrey: #AAAAAB;
    --slategrey: #4C5758;
    --innerElementWith: 90%;
    --borderSettings: rgb(204, 204, 204) solid 1px;
}

.fileInterfacecontainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    /* Optional: Adds space between the two columns */
}

.leftColumn {
    flex: 1;
    padding: 10px;
    height: 600px;
}

.rightColumn {
    flex: 1;
    padding: 10px;
}

.displayDataMap {
    width: 100%;
    height: 100%;
}

.instructions {
    position: relative;
    left: 0px;
    /* top: 15px */
}

.map {
    position: relative;
    height: 650px !important;
    min-height: 650px !important;
    top: 25px;
    right: 0px;
}

.map-container {
    height: 100%;
}

.mapboxgl-ctrl-attrib {
    display: none;
}

.createMapButton {
    cursor: pointer;
    background-color: var(--darkgreen);
    padding: 20px;
    border-radius: 3px;
    border: 0px;
    font-size: 14px;
    color: #fff;
    width: 100%;
}

.fileInputHeader {
    position: relative;
    border-radius: 10px;
    left: 50%;
    transform: translate(-50%);
    width: 45%;
    text-align: center;
    /* background-color: var(--darkgreen); */
    padding: 5px;
    margin-top: 20px;
}

.initFileInput {
    overflow-y: scroll;
    display: block;
    position: absolute;
    border-radius: 10px;
    left: 50%;
    max-width: 1150px;
    min-width: 900px;
    z-index: 1;
    max-height: 700px;
    min-height: 700px;
    top: 50%;
    background-color: #fff;
    border: solid 1px var(--midgrey);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    transform: translate(-50%, -50%);
    width: 45%;
    padding: 20px;
    height: 60%;
}

.coreInstructionBlockContainer {
    text-align: center;
    /* background-color: #ff0000; */
    width: 100%;
    position: relative;
    height: 50px;
    top: 25px;
    margin-bottom: 50px;
}

.coreInstructionBlock,
.coreInstructionBlockActive {
    position: relative;
    border-radius: 3px;
    margin-left: 3%;
    margin-right: 3%;
    font-weight: bold;
    display: inline-block;
    width: 85px;
    /* border: 1px solid var(--darkgreen); */
    text-align: center;
    padding: 10px;
    color: var(--slategrey);
    background-color: var(--lightgrey);
}

.coreInstructionBlockActive {
    background-color: var(--darkgreen);
    color: #fff;
}

.dropzone {
    width: 100%;
    height: 55%;
    position: relative;
    /* border: 2px dashed var(--midgrey); */
    border-radius: 10px;
    display: flex;
    /* left: 50%;
    transform: translate(-50%, -50%); */
    background-color: var(--lightgrey);
    color: var(--slategrey);
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fileInput {
    overflow-y: scroll;
    display: block;
    position: absolute;
    border-radius: 10px;
    left: 50%;
    transform: translate(-50%);
    width: calc(80%);
    padding: 20px;
    min-height: 750px;
}

.loadedDataDisplay,
.convertedGeoJsonDisplay,
.transformedGeoJsonDisplay,
.middleReprojectedDataDisplay {
    position: relative;
    border-radius: 5px;
    border: var(--borderSettings);
    overflow-y: auto;
    overflow-x: hidden;
    width: var(--innerElementWith);
    margin-top: 25px;
    padding: 20px;
    height: 50px;
}

.sourceprojectionSelector,
.reprojectionSelector,
.destinationprojectionSelector {
    margin-top: 30px;
    position: relative;
    width: calc(var(--innerElementWith) + 40px);
    padding-top: 5px;
    padding-bottom: 5px;
}

.sourceprojectionSelector select,
.reprojectionSelector select,
.destinationprojectionSelector select {
    /* float: right; */
}

.fileDropArea {
    width: var(--innerElementWith);
    border-radius: 10px;
}

.fileDropArea:hover {
    background-color: var(--hovergrey);
    cursor: pointer;
}

.fileNameDisplay {
    padding-top: 10px;
}

.convertGeoJsonButton,
.downloadGeoJsonButton {
    position: relative;
    border-radius: 10px;
    width: calc(var(--innerElementWith) + 40px);
    padding-top: 20px;
    display: block;
}

.showConvertGeoJsonButton {
    position: relative;
    border-radius: 10px;
    width: calc(var(--innerElementWith) + 40px);
    padding-top: 20px;
    display: block;
}

.createHarrierMapButton {
    position: relative;
    border-radius: 10px;
    width: calc(var(--innerElementWith) + 40px);
    padding-top: 20px;
    margin-top: 20px;
    display: block;
}

.convertGeoJsonButton button {
    background-color: var(--slategrey);
}

.showConvertGeoJsonButton button {
    background-color: var(--darkgreen);
    margin-top: 0px;
}

.showhidebutton {
    position: relative;
    border-radius: 10px;
    width: calc(var(--innerElementWith) + 40px);
    padding-top: 20px;
    display: block;
}

.metricSelectionOptions {
    position: relative;
    border-radius: 10px;
    /* width: calc(var(--innerElementWith) + 40px); */
    /* padding: 5px; */
    display: block;
    /* background-color: var(--darkgreen); */
    /* border: 1px solid var(--darkgreen); */
    border-radius: 5px;
    overflow: scroll;
    max-height: 200px;
    /* background-color: var(--lightgrey); */
    /* border: 1px solid #29645d; */
    /* border: 1px solid var(--darkgreen); */
    border-radius: 5px;
}

.metricSelectionOption {
    display: inline-block;
    width: auto;
    margin: 5px;
    text-align: center;
    padding: 10px;
    /* border: 1px solid var(--darkgreen); */
    border-radius: 5px;
    cursor: pointer;
    background-color: #fff;
}

/* Hide the default checkbox */
.custom-checkbox {
    display: none;
}

/* Create a custom checkbox */
.custom-checkbox+label {
    position: relative;
    cursor: pointer;
    padding-left: 25px;
    font-size: 16px;
    color: #333;
}

.custom-checkbox label {
    margin-left: 20px;
}

/* The box to replace the default checkbox */
.custom-checkbox+label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 17px;
    height: 18px;
    border: 2px solid #333;
    /* margin: 5px; */
    border-radius: 20px;
    background-color: #fff;
}

/* Checkmark/indicator (hidden by default) */
.custom-checkbox+label::after {
    content: '';
    position: absolute;
    left: 5px;
    top: 2px;
    width: 8px;
    height: 12px;
    /* border: solid #fff; */
    /* border-width: 0 2px 2px 0; */
    /* transform: rotate(45deg); */
    opacity: 0;
}

/* When the checkbox is checked, show the checkmark */
.custom-checkbox:checked+label::before {
    background-color: var(--darkgreen);
    border-color: var(--darkgreen);
}

.custom-checkbox:checked+label::after {
    opacity: 1;
}

.SubmitGeoJsonButton {
    position: relative;
    border-radius: 10px;
    width: calc(var(--innerElementWith) / 8 + 40px);
    padding-top: 20px;
    display: block;
    padding-bottom: 20px;
}

.palettes-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    text-align: center;
}

.palette {
    /* border: 1px solid #ddd; */
    border-radius: 5px;
    background-color: var(--lightgrey);
    padding: 10px;
    cursor: pointer;
    width: 105px;
}

.palette-name {
    /* font-weight: bold; */
    /* margin-bottom: 10px; */
}

.color-swatches {
    /* display: flex;
    gap: 2.5px; */
}

.color-swatch {
    width: 5px;
    height: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.palette.selected {
    /* border: 3px solid #000; */
    background-color: var(--darkgreen);
    color: #fff;
    font-weight: bold;
    /* Bold border for selected palette */
}

.convertInitButton {
    /* position: relative; */
    right: 0px;
    margin-top: 30px;
    float: right;
    display: inline-block;
    /* margin-top: 0px; */
    /* top: 0px; */
}

.converterButton {
    cursor: pointer;
    background-color: var(--darkgreen);
    padding: 10px;
    border-radius: 3px;
    border: 0px;
    font-size: 14px;
    color: #fff;
    width: 135px;
    margin-top: 20px;
}